.slider-card {
   height: auto;
   min-height: 100%;
   justify-content: space-between;
   width: 100%;
   border: 1px solid #e0e0e0;
   position: relative;
   border-radius: 12px;
   padding: 8px;
   &-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 10px;
   }
   &-detail {
      display: flex;
      flex-direction: column;
      &-name {
         font-family: "Poppins" sans-serif;
         font-weight: 500;
         font-size: 13px;
         line-height: 19.5px;
         letter-spacing: 0%;
      }
      &-price {
         font-family: "Poppins" sans-serif;
         font-weight: 700;
         font-size: 13px;
         line-height: 19.5px;
         letter-spacing: 0%;
      }
      &-img {
         overflow: hidden;
         width: 100%;
         height: 220px;

         border-radius: 8px;
         object-fit: cover;
         padding: 3px;
         @media (max-width: 1440px) {
            height: 150px;
         }
         @media (max-width: 720px) {
            height: 150px;
         }
      }
   }

   &-right {
      &-badge {
         top: 3px;
         right: 3px;
         font-size: 8px;
         border-radius: 50px;
         padding: 3.26px 8px 2px 8px;
         background: #1374d526;
         line-height: normal;
         color: #0b4d89;
      }
   }
   button {
      &::before {
         display: none !important;
      }
   }

   p {
      margin-bottom: 0px;
   }
   .slick-slide div {
      display: flex !important;
      width: inherit;
      justify-content: center;
      align-items: center;
   }
   .slick-prev {
      left: 25px;
   }
   .slick-next {
      right: 25px;
   }
   .slick-disabled {
      display: none;
   }
   .slick-prev,
   .slick-next {
      z-index: 5;
      background: none !important;
   }
}
