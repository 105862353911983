//Colors
$gray-light: #74788d;
$gray-light-2: #777777;
$gray-light-3: #e0e0e0;
$gray-light-4: #eff2f7;
$gray-light-5: #ced4da;
$gray-light-6: #f3f3f9;
$gray-light-7: #bbbbbb;
$gray-light-8: #dcdcdc;
$gray-dark: #495057;
$gray-dark-2: #74788d;
$gray-dark-3: #9f9797;
$gray-dark-4: #7d7d7d;
$light-blue: #b7daea;
$light-blue-2: #ebf2f5;

$black: #000;
$white: #fff;
$white-2: #f1f1f1;
$red: #fc0000;
$red-2: #fc0000;
$green: #30b69e;
$green-2: #02ac40;
$green-3: #04a74a;
$blue: #556ee6;
$pink: #e02bc3;
$orange: #ff9900;
$orange-2: #fc8f00;
$yellow: #eebe48;
$yellow-2: #fcd200;
$purple: #556ee6;
$blue-2: #4c8fed;
// Casita theme color
$casita-blue: #0b4d89;
$casita-blue-grad: transparent linear-gradient(0deg, #2c3e50 0%, #2980b9 100%)
   0% 0% no-repeat padding-box;

$body-bg: #f8f8fb;
$sidenav-grad-secondary: #ffffff00;
$shadow-clr: #00000033;
$shadow-clr-2: #00000070;
$table-head-bg: #f8f9fa;
$pdt-select-dropdown: #c9c4c4;
$qty-btn: #afafaf;

$bdr-clr: #ced4da;
$bdr-clr-2: #adb5bd;
$bdr-clr-3: #707070;
$bdr-clr-4: #d9d9d9;
$bdr-clr-5: #b9b9b9;
$bdr-clr-6: #d6d6d6;
$table-font-color: #495057;

$pdt-clr-red: #fe4069;
$pdt-clr-blue: #5b77ff;
$pdt-clr-green: #67c259;
$pdt-clr-pink: #ef67e6;
$pdt-clr-yellow: #ffcf4d;
$pdt-clr-black: #000000;

$badge-pending-clr: #fc8f00;
$badge-verified-clr: #02ac40;
$badge-delivered-clr: #02ac40;
$badge-intransit-clr: #fcd200;
$badge-cancelled-clr: #f46a6a;
$badge-active-clr: #02ac40;
$badge-suspended-clr: #f46a6a;
$badge-invited-clr: #fcd200;
$badge-rejected-clr: #f46a6a;
$badge-mockups-pending-clr: #4c8fed;
$badge-mockups-ready-clr: #4c8fed;
$badge-production-pending-clr: #fcd200;
$badge-production-ready-clr: #02ac40;
$badge-completed-clr: #02ac40;
$badge-blue-bg: #1374d5;
$btn-table-bg: #0b4d89;
$btn-table-lg: #ced4da;
//Margins & Paddings
$xxl: 5rem; //80px
$xl: 4.5rem; //72px
$lg: 3.5rem; //56px
$md: 2.5rem; //40px
$sm: 2rem; //32px
$xs: 1rem; //16px
$xxs: 0.5rem; //8px

//Margins & Paddings
// $xxl: em(80); //80px
// $xl: em(72); //72px
// $lg: em(56); //56px
// $md: em(40); //40px
// $sm: em(32); //32px
// $xs: em(16); //16px
// $xxs: em(8); //8px
