.custom-navlink {
   background-color: white !important;
   border: 1px solid #c4c4c4 !important;
   padding: 8px 16px !important;
   border-radius: 6px !important;
   color: black !important;
   cursor: pointer !important;
   margin-right: 8px !important;
   transition: background-color 0.3s ease !important;
}

.custom-navlink.active {
   background-color: #1374d5 !important;
   color: white !important;
   border-color: transparent !important;
}

// Common
.configurator-active {
   background-color: #34c38f40;
   color: #004a2f;
}

.configurator-inactive {
   background-color: #f46a6a40;
   color: #950000;
}

.configurator-package-customizable {
   background-color: transparent;
   color: #1374d5;
}

.configurator-status {
   font-weight: 400 !important;
   font-size: 12px;
   border: 1px solid transparent;
   border-radius: 32px;
   width: 70px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 2px 0;
}

// Sales And Marketing | Build | Styles

.accordion-title-blue {
   color: #1374d5;
   font-weight: 600;
   font-size: 13px;
   display: flex;
   align-items: center;
}

.accordion-title-black {
   color: #40393a;
   font-weight: 600;
   font-size: 13px;
   display: flex;
   align-items: center;
}

.custom-tabs {
   border-bottom: 1px solid #e8e8e8;
}

.custom-tabs .nav-link {
   font-weight: normal;
   color: #000000;
   border: none;
   padding: 0.8rem 1rem;
   transition: color 0.3s;
   font-size: 13px;
}

.custom-tabs .nav-link.active {
   color: #1890ff;
   position: relative;
   font-weight: bold;
   font-size: 13px;
}

.custom-tabs .nav-link.active::after {
   content: "";
   position: absolute;
   bottom: -1px;
   left: 0;
   right: 0;
   height: 3px;
   background-color: #1890ff;
}

.custom-tabs .nav-link:hover {
   color: #1890ff;
}
.accordion-header-right {
   margin-right: 5px;
   border: 1px solid #c4c4c4;
   border-radius: 5px;
   padding: 3px;
   width: fit-content;
}
