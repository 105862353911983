.item-card {
   height: 76px;
   overflow: hidden;
   display: flex;
   justify-content: space-between;
   width: 100%;
   border: 1px solid #e0e0e0;
   position: relative;
   border-radius: 12px;
   padding: 8px;
   &-detail {
      display: flex;
      align-items: center;
      gap: 15px;
      &-name {
         font-family: "Poppins" sans-serif;
         font-weight: 400;
         font-size: 13px;
         line-height: 19.5px;
         letter-spacing: 0%;
      }
   }

   &-right {
      display: flex;
      flex-direction: column;
      gap: 3px;
      align-items: center;
      justify-content: flex-end;
      &-badge {
         top: 3px;
         right: 3px;
         font-size: 8px;
         border-radius: 50px;
         padding: 3.26px 8px 2px 8px;
         background: #1374d526;
         line-height: normal;
         color: #0b4d89;
      }
      &-price {
         font-family: "Poppins" sans-serif;
         font-weight: 700;
         font-size: 13px;
         line-height: 19.5px;
         letter-spacing: 0%;
      }
   }

   p {
      margin-bottom: 0px;
   }

   img {
      overflow: hidden;
      width: 80px;
      height: 60px;
      border-radius: 5px;
      object-fit: cover;
   }
}
